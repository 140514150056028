import axios from "axios";
const apiUrl = "https://analogx.seedx.live/MerkleNew/public/index.php/api/";
export const getUserRandomidToId = async (userId) => {
  try {
    const data = await axios.get(`${apiUrl}registration/${userId}`);

    return data.data.data;
  } catch (e) {
    // console.log('dincatch:',e)
    return [];
  }
};

export const getUserRandomId = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}registration/${userAddress}`);

    return data.data;
  } catch (e) {
    // console.log('dincatch:',e)
    return [];
  }
};

export const getDirectTeam = async (userAddress, page) => {
  try {
    const data = await axios.get(
      `${apiUrl}referral/${userAddress}?page=${page}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getLevelIncome = async (userAddress, level, page) => {
  try {
    const data = await axios.get(
      `${apiUrl}level_incomeV2/${userAddress}/${level}?page=${page}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getGrowthLevelIncome = async (userAddress, level, page) => {
  try {
    const data = await axios.get(
      `${apiUrl}growth_lvl_stakeV2/${userAddress}/${level}?page=${page}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getStakedIncome = async (userAddress, page) => {
  try {
    const data = await axios.get(
      `${apiUrl}stakedV2/${userAddress}`
    );
    console.log(data, "dat:::");
    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getTreeView = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}tree/${userAddress}`);

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getUserDashInfo = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}dashboard/${userAddress}`);

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getTeamInfo = async (userAddress) => {
  // console.log(userAddress, "data");
  try {
    const data = await axios.get(`${apiUrl}team/${userAddress}`);

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const setProfileInfo = async (formData) => {
  try {
    const formDatas = await axios.post(`${apiUrl}register`, {
      customer_name: formData?.name,
      email_id: formData?.email,
      country: formData?.state,
      mobile_no: formData?.mobCode,
      wallet: formData?.walletAddress,
    });

    return formDatas;
  } catch (e) {
    console.error("Error:", e);
  }
};

export const getProfileInfo = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}profile/${userAddress}`);

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getUserAllIncome = async (userAddress) => {
  try {
    const data = await axios.get(
      `${apiUrl}incomeV2/${userAddress}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getUserGrowthClaim = async (userAddress, page) => {
  try {
    const data = await axios.get(
      `${apiUrl}growth_claim_rewardV2/${userAddress}/?page=${page}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getUserStakedClaim = async (userAddress, page) => {
  try {
    const data = await axios.get(
      `${apiUrl}claim_staking_rewardV2/${userAddress}?page=${page}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getUserStakedWihdrawToken = async (userAddress, page) => {
  try {
    const data = await axios.get(
      `${apiUrl}withdraw_stake_tokenV2/${userAddress}?page=${page}`
    );
    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const setRewardIncomeByUser = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}reward/${userAddress}`);
    // console.log(data, "reward");
    return data;
  } catch (e) {
    return [];
  }
};

export const RewardIncomeWithdraw = async (userAddress) => {
  try {
    const data = await axios.post(`${apiUrl}withdraw_token`, {
      user: userAddress,
    });
    // console.log(data, "reward");
    return data;
  } catch (e) {
    return [];
  }
};

export const RewardIncomeView = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}withdraw_balance/${userAddress}`);

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const RewardIncomeDetail = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}withdraw_details/${userAddress}`);

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const RewardStatus = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}rank_status/${userAddress}`);

    return data.data;
  } catch (e) {
    return [];
  }


};

export const addRewardUsdtAmont = async (userData) => {
  try {
    const data = await axios.post(`${apiUrl}addReward_amtUSDT`, {
      userData
    });
   
    return data;
  } catch (e) {
    return [];
  }
};

export const getAllBusiness = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}today-business/${userAddress}`);

    return data.data;
  } catch (e) {
    return [];
  }


};

export const getCurrentPrice= async () => {
  try {
    const data = await axios.get(`${apiUrl}current-price-Btyc`);

    return data.data;
  } catch (e) {
    return [];
  }


};

export const getBuyBackData = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}get-Buyback/${userAddress}`);

    return data.data;
  } catch (e) {
    return [];
  }
};
export const getBuyBackMBTYCData = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}get-Buyback-mBTYC/${userAddress}`);

    return data.data;
  } catch (e) {
    return [];
  }
};

export const getBuyBackWithDrwalData = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}get-Buyback-balance/${userAddress}`);

    return data.data;
  } catch (e) {
    return [];
  }
};
export const getbuyBackBalancemBtyc = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}get-Buyback-mBTYCbalance/${userAddress}`);

    return data.data;
  } catch (e) {
    return [];
  }
};
export const getBuyBackWithDrwalDetails = async (userAddress) => {
  try {
    const data = await axios.get(`${apiUrl}get-Buyback-withdrawDetails/${userAddress}`);

    return data.data;
  } catch (e) {
    return [];
  }
};

export const setWithwalDetails = async (formData) => {
  try {
    const response = await axios.post(`${apiUrl}buybackwithdwal`, formData);
    return response.data;

  } catch (e) {
    console.error("Error:", e);
  }
};
